import React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'
import Map from '../components/contacts/map'

import '../sass/contacts.scss';

const ContactPage = ({ location }) => {
  const breakpoints = useBreakpoint();

  const { t } = useTranslation()

  const dataGlobal = t("global", { returnObjects: true })
  const dataContact = t("contact", { returnObjects: true })

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} removeCTA={true} page={dataContact.page} seo={dataContact.seo} location={location}>

      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/contactos_mobile_byoss5.mp4"
            global={dataGlobal} textstatic={dataContact} mobile centered={true}
            location={location} />
        </>
        :
        <>
          <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/Contactos_desktop__uj9pom.mp4"
            global={dataGlobal} textstatic={dataContact}
            location={location} />

        </>

      }
      <SmoothScroll>
        <Map data={dataContact} mobile={breakpoints.mobile}/>


      </SmoothScroll>

    </Layout>
  )
}

export default ContactPage


export const pageQuery = graphql`
      query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["global", "contact"]}, language: {eq: $language}}) {
        edges {
        node {
        ns
            data
      language
          }
        }
    }
  }
      `